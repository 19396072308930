import { useQueries } from "@tanstack/react-query";
import { LatLngBounds } from "leaflet";
import React from "react";
import { useApiClient } from "../../Util/ApiClient";
import { IProfileMapMarkerProps } from "../../Util/AppData";
import { MapMarkerProfilesScaled } from "./MapMarkerProfilesScaled";
import { useMapMarkerProfilesWithMyMarker } from "./MapMarkerProfilesWithMyMarker";
import { ProfileLocationControlType } from "./MapPage";
import { MapMarkerProfilePositionAggregates } from './MapMarkerProfilePositionAggregates';

interface IMapMarkerProfilesActiveComponent {
  bounds: LatLngBounds
  setDisappearingMessage : (msg: string) => void
  profileLocationControlType: ProfileLocationControlType
}
const MapMarkersBySectorParallel = ({ bounds, profileLocationControlType }: IMapMarkerProfilesActiveComponent) => {
  const REFETCH_SECS = 15
  
  const apiClient = useApiClient()
  
  const createDecimalSegments: (str1: string, str2: string) => string[] = (str1, str2) => {    
    //Fix Order
    let num1: number = Number(str1)
    let num2: number = Number(str2)
    let num1Str = str1
    let num2Str = str2
    if (num1 > num2) {
       num1 = Number(str2)
       num2 = Number(str1)
       num1Str = str2
       num2Str = str1
    } //console.log(num1, num2, num1 <= num2)

    var segments: string[] = []
    for (let y = Math.floor(num1); y <= Math.floor(num2); y++) {
      segments.push("" + y)
    } //console.log("segments", segments)
    return segments
  }

  const ranges = { latRanges: createDecimalSegments("" + bounds.getSouth(), "" + bounds.getNorth()), lonRanges: createDecimalSegments("" + bounds.getWest(), "" + bounds.getEast()) }
  const numRanges = ranges.latRanges.length * ranges.lonRanges.length
  //console.log("Segements calculated: ", JSON.stringify({bounds, ranges}))
  //console.log(ranges.latRanges)
  //console.log(ranges.lonRanges)

  let bshowWorldMarkers = false
  if (numRanges > 20) {
    bshowWorldMarkers = true
  }

  let rangeQueries = ( 
    bshowWorldMarkers
    ? [] 
    : ranges.latRanges
      .map(lat => ranges.lonRanges.map(lon => ({ lat: lat, lon: lon })))
      .flatMap(llRanges => 
        llRanges.map(ll => ({
          queryKey: ["mapMarkersBySectorGet", ll.lat, ll.lon],
          queryFn: () => apiClient.mapMarkersBySectorGet(ll.lat, ll.lon, 0, 1500),
          refetchOnWindowFocus: true,
          //refetchInterval: 1000 //1 second
          refetchInterval: REFETCH_SECS * 1000, 
        }))
      )     
  )
  
  const startTime = new Date().getTime()
  const markerQueries = useQueries({
    queries: rangeQueries,
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
        pending: results.some((result) => result.isPending),
      }
    },        
  })

  var profileMapMarkersPropsArr = markerQueries.data.filter(d => d !== undefined).flatMap(d => d) as unknown as IProfileMapMarkerProps[]
  //console.log("before", profileMapMarkersPropsArr.length)
  var profileMapMarkersPropsMap = useMapMarkerProfilesWithMyMarker({profileMapMarkersPropsArr})
  //console.log("after", Array.from(profileMapMarkersPropsMap.values()).length)
  return (
    <>
      { 
        <MapMarkerProfilesScaled 
          profileMapMarkersPropsMap={profileMapMarkersPropsMap} 
          bounds={bounds} 
          profileLocationControlType={profileLocationControlType} 
        /> 
      }
      { bshowWorldMarkers && <MapMarkerProfilePositionAggregates bounds={bounds}/> }
    </>
  )
}

export { MapMarkersBySectorParallel };

