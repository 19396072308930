import { Bullseye, Button, Gallery, GalleryItem, PageSection } from '@patternfly/react-core';
import { useMeasure } from '@react-hookz/web';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { LatLng } from 'leaflet';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { IProfileMapMarkerProps } from '../../Util/AppData';
import { AppSpinner, colloquialDistance, profilePicSteppedWidthApiUrl } from '../../Util/AppUtil';
import { LocationReportingContext } from '../../Util/LocationReportingContext';
import { useNearestMapMarkers } from '../MapMarkerData';
import { ITile, TilesGrid } from '../../Widgets/TilesGrid';
//TODO  Implement Inf Scrolling and Filtering 
//https://codesandbox.io/s/infinite-scrolling-useref-g4fgz?file=/src/App.js

interface ITilesPage { }
const TilesPage: React.FunctionComponent<ITilesPage> = (props) => {
  const history = useHistory()
  const locationReporting = React.useContext(LocationReportingContext)
  const { mapMarkers, data, fetchNextPage, hasNextPage, isFetching, isLoading } = useNearestMapMarkers({pageSize: 30})
  const [loadMoreElement, entry] = useIntersectionObserver({ threshold: 0, root: null, rootMargin: "50%" });
  
  if (!locationReporting || !fetchNextPage) return <></>

  React.useEffect(() => {
    if (entry) {
      console.log("Ratio crossing: " + entry.intersectionRatio)
      if (entry?.intersectionRatio > 0 && !isFetching) {
        fetchNextPage()
      }
    }
  }, [entry])

  const tiles = mapMarkers.map(mm => {
    const tile : ITile  = { 
      imageUrlOfWidth:(width: number) => profilePicSteppedWidthApiUrl(mm.profileMapMarkerProperties.profilePicIds[0], width),
      onClick:() => history.push("/profile?id=" + mm.profileMapMarkerProperties.profileId),
      topRightLabelText: "" + mm.collDist,
      bottomRightLabel:mm.profileMapMarkerProperties.profileName
    }
    return tile;
  })

  //console.log("Rendering Search");
  const widths = { default: "33%", sm: '33%', md: '25%', lg: '20%', xl: '10%', "2xl": '10%' }
  return (
    <PageSection height="100%" width="100%" style={{ position: "relative", margin: 0, padding: 0 }}>
      {/* <>Rendered Search : { JSON.stringify({ts: Date.now(), isFetched, isLoading, dataLe: mapMarkers?.length}) }</> */}
      {isLoading || !data
        ? <AppSpinner />
        :
        <TilesGrid tiles={tiles} requestMore={fetchNextPage} hasNextPage={hasNextPage} />        
      }
    </PageSection>
  )
}


export { TilesPage };
