import * as React from 'react';
import { ActionGroup, Alert, Button, Checkbox, Form, FormGroup, HelperText, InputGroup, InputGroupItem, Label, PageSection, Text, TextInput, TextVariants, Title } from '@patternfly/react-core';
import { Link, useHistory } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../../Util/ApiClient';
import { IPushNotificationSubscription, ISettings } from '../../Util/AppData';
import { LocationReportingContext } from '../../Util/LocationReportingContext';
import { LocationReportingType } from '../../Util/LocationReporting';
import { LatLng, LatLngExpression } from 'leaflet';
import { AppPushNotifications } from '../../Auth/AppPushNotifications';
import { EyeIcon, EyeSlashIcon } from '@patternfly/react-icons';
import { geolocationPositionErrorMessage } from '@app/Util/AppUtil';

const QUERY_KEY_SETTINGS = 'settingsGet';

const SettingsPage = () => {
  const queryClient = useQueryClient()
  const apiClient = useApiClient()
  const [settings, setSettings] = React.useState<ISettings>()
  const [password1, setPassword1] = React.useState("")
  const [password2, setPassword2] = React.useState("")
  const [showPassword, setShowPassword] = React.useState(false)
  const [passwordErrorMsg, setPasswordErrorMsg] = React.useState("")
  const [saveMessage, setSaveMessage] = React.useState("")
  const [thisDevicePushSubscription, setThisDevicePushSubscription] = React.useState<PushSubscription>()

  const { status, data, error, isFetching } = useQuery<ISettings, Error>({
    queryKey: [QUERY_KEY_SETTINGS],
    queryFn: apiClient.settingsGet,
    staleTime: Infinity
  });

  React.useEffect(() => {
    setSettings(data)
  }, [data])

  const locationReporting = React.useContext(LocationReportingContext)
  const history = useHistory();

  const saveMutation = useMutation<ISettings>({
    mutationFn: () => new Promise(() => { if (settings) apiClient.settingsPut(settings) }),
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: [QUERY_KEY_SETTINGS]});
      setSaveMessage("Saved");
    }
  })

  let errorMsg;
  if (status === 'pending') errorMsg = (<h1>Loading...</h1>)
  else if (status === 'error') errorMsg = (<span>Error: {error.message}</span>)
  else errorMsg = (<span>{status}</span>)

  const geoError = geolocationPositionErrorMessage(locationReporting?.locationDevice?.error)
  const geoPos = locationReporting?.locationDevice?.loading ? "Location Loading" : "GPS : " + locationReporting?.deviceLatLng.lat + ", " + locationReporting?.deviceLatLng.lng
  const geoStatus = geoError ? geoError : geoPos

  //https://github.com/web-push-libs/webpush-java/issues/30
  function base64UrlToUint8Array(base64UrlData) {
    const padding = '='.repeat((4 - base64UrlData.length % 4) % 4);
    const base64 = (base64UrlData + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');
    const rawData = atob(base64);
    const buffer = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
      buffer[i] = rawData.charCodeAt(i);
    }
    return buffer;
  }

  //const arrToBase64 = (a) =>  a ? btoa(a.toString()) : ""
  //const arrToBase64 = (a : string) =>  a ? a.toString() : ""
  const arrToBase64 = (a: ArrayBuffer | null) =>
    a
      ? btoa(String.fromCharCode.apply(null, Array.from(new Uint8Array(a))))
      : ''

  React.useEffect(() => {
    if (password1 !== password2) {
      setPasswordErrorMsg("Passwords do not match");
    } else {
      setPasswordErrorMsg("");
      if (settings) setSettings({ ...settings, password: password2 })
    }
  }, [password1, password2])

  console.log("RENDER Settings")
  return (
    <PageSection>
      <Title headingLevel="h1" size="lg">
        Account Settings
      </Title>
      {
        status !== 'success' || !settings
        ? errorMsg
        : <>
          These settings are unrelated to your public profile.<br />
          <br />
          <Form isWidthLimited >
            <FormGroup
              label="Name"
              isRequired
              fieldId="horizontal-form-name"
            >
              <TextInput
                value={settings.name}
                isRequired
                type="text"
                id="horizontal-form-name"
                aria-describedby="horizontal-form-name-helper"
                onChange={(e, v) => setSettings({...settings, name:v})}
              />
              <HelperText>This is your private conatact name for email.</HelperText>
            </FormGroup>
            <FormGroup label="Email" isRequired fieldId="horizontal-form-email">
              <TextInput
                value={settings.email}
                isRequired
                type="email"
                id="horizontal-form-email"
                onChange={(e, v) => setSettings({...settings, email:v})}
              />
            </FormGroup>

            <FormGroup label="Password" isRequired fieldId="horizontal-form-password1">
              <InputGroup>
                <InputGroupItem isFill>
                  <TextInput
                    isRequired
                    autoComplete='new-password'
                    type={showPassword ? "text" : "password"}
                    id="horizontal-form-password1"
                    onChange={(e, v) => setPassword1(v)}
                  />
                </InputGroupItem>
                <InputGroupItem>
                  <Button
                    variant="control"
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label={showPassword ? "Show Passwords" : "Hide Password"}
                  >
                    {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                  </Button>
                </InputGroupItem>
              </InputGroup>
              <InputGroup>
                <InputGroupItem isFill>
                  <TextInput
                    isRequired
                    autoComplete='new-password'
                    type={showPassword ? "text" : "password"}
                    id="horizontal-form-password2"
                    onChange={(e, v) => setPassword2(v)}
                  />
                </InputGroupItem>
                <InputGroupItem>
                  <Button
                    variant="control"
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label={showPassword ? "Show Passwords" : "Hide Password"}
                  >
                    {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                  </Button>
                </InputGroupItem>
              </InputGroup>
              {passwordErrorMsg && <Alert variant='danger' title={passwordErrorMsg} />}
            </FormGroup>

            <FormGroup label="Device Push Notifications" isRequired fieldId="horizontal-form-notifications">
              {
                settings.pushSubscriptions.map((pne, index) => {
                  const thisDevice = (() => {
                    const auth = thisDevicePushSubscription?.getKey("auth") ? thisDevicePushSubscription?.getKey("auth") : new Uint8Array()
                    if (!auth) return false
                    console.log([new Uint8Array(auth).toString(), base64UrlToUint8Array(pne.auth_base64).toString()])
                    return new Uint8Array(auth).toString() == base64UrlToUint8Array(pne.auth_base64).toString()
                  })()
                  return (
                    <div key={pne.endpoint}>
                      <Checkbox
                        value={pne.endpoint}
                        isChecked={true}
                        isRequired
                        id="horizontal-form-override-location"
                        onChange={(e, v) => {
                          const newSettings = {...settings, pushSubscriptions: settings.pushSubscriptions.filter(
                            (pne2, i) => {
                              console.log(["Removing", pne.deviceName, pne.p256dh_base64, pne.expirationTime])
                              return !(pne.deviceName == pne2.deviceName && pne.p256dh_base64 == pne2.p256dh_base64 && pne.expirationTime == pne2.expirationTime)
                            }
                          )}
                          if (thisDevice) {
                            navigator.serviceWorker.getRegistration().then(r => {
                              r?.pushManager.getSubscription().then(s => {
                                s?.unsubscribe()
                                setThisDevicePushSubscription(undefined)
                              })
                            })
                          }
                          setSettings(newSettings)
                        }}
                      />
                      &nbsp;
                      <span>{pne.deviceName}{
                        thisDevice
                          ? " (this device)"
                          : <></>
                      }</span>
                    </div>
                  )
                })
              }
              {
                thisDevicePushSubscription
                  ? <></>
                  : <AppPushNotifications
                    onPushNotificationSubscription={(deviceName, pn) => {
                      setThisDevicePushSubscription(pn)
                      const getPn = (deviceName: string, subscription: PushSubscription): IPushNotificationSubscription => {
                        const s = subscription
                        return {
                          deviceName,
                          endpoint: s.endpoint,
                          expirationTime: s.expirationTime ? s.expirationTime : 0,
                          auth_base64: arrToBase64(s.getKey("auth")),
                          p256dh_base64: arrToBase64(s.getKey("p256dh")),
                          applicationServerKey: settings.applicationServerKey
                        }
                      }

                      const filtered = settings.pushSubscriptions.filter((pnold) => pnold.deviceName != deviceName)
                      settings.pushSubscriptions = [...filtered, getPn(deviceName, pn)]
                      setSettings({ ...settings })
                    }}
                    applicationServerKey={base64UrlToUint8Array(settings.applicationServerKey)}
                  />
              }
            </FormGroup>
            {status !== "success" ? <div>There has been an error</div> : <></>}
            {saveMessage !== "" ? <div>{saveMessage}</div> : <></>}
            <ActionGroup>
              <Button onClick={() => saveMutation.mutate()}>Save</Button>
            </ActionGroup>
          </Form>

        </>
      }
      {console.log(settings?.pushSubscriptions.map((pn) => pn.deviceName))}
    </PageSection>
  )
}

export { SettingsPage };