import * as React from 'react';
import { keepPreviousData, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '@app/Util/ApiClient'
import { IProfile, IProfileNote } from '@app/Util/AppData';
import { TextArea } from '@patternfly/react-core';
import { EventListeners } from '@patternfly/react-core/dist/esm/helpers/Popper/thirdparty/popper-core';
import { html } from '@app/Util/AppUtil';

const descLimit = 4000;

interface IProfileNoteComponent {
  aboutProfileId: string
  saveButtonOnClickDoThisToo:(runnable: ()=> void) => void
}
const ProfileNoteEditor: React.FunctionComponent<IProfileNoteComponent> = ({aboutProfileId, saveButtonOnClickDoThisToo}) => {
  const descRef = React.useRef();

  const [bodyText, setBodyText] = React.useState("...loading");
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const QUERY_KEY = ["profileNoteGet", aboutProfileId]

  const { status, data: profileNote, error, isLoading } = useQuery<IProfileNote, Error>({
    queryKey: QUERY_KEY,
    queryFn: () => apiClient.profileNoteGet(aboutProfileId),
    placeholderData: keepPreviousData,  
    refetchOnWindowFocus: false,      
  });

  const mutation = useMutation({
    mutationFn: () => apiClient.profileNotePut(aboutProfileId, bodyText),
    onSuccess: () => {
        queryClient.invalidateQueries({queryKey: QUERY_KEY})        
    },
  });

  React.useEffect(
    () =>{
      saveButtonOnClickDoThisToo(()=> {        
        mutation.mutate()
      })
    }
    , [saveButtonOnClickDoThisToo]
  )

  //let strJson  = JSON.stringify({profilename,birthdate,height,weight,selectedChars,description })

  const saveProfile = (p: IProfile) => mutation.mutate();

  return (
    isLoading || profileNote == undefined
      ? <div>Loading...</div>
      : <div>
        <TextArea
                style={{ minHeight: "9em" }}
                defaultValue={profileNote.bodyText}
                maxLength={descLimit}
                autoResize
                onChange={(e,v) => setBodyText(v)}
                id="horizontal-form-exp"
                name="horizontal-form-exp"
              />
        </div>
  )
}

export { ProfileNoteEditor };

