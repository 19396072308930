import * as React from 'react';
import { JumpLinks, JumpLinksItem } from '@patternfly/react-core';
import { useLocation } from 'react-router-dom';

interface PageLink { href: string, title: string }
const LikesJumpLinks = () => {
  const pageLinks : PageLink[] = [
    {href:"/likes/profilelikedyou", title:"Liked You"},
    {href:"/likes/profilelikedyourpics", title:"Your Pics"},
    {href:"/likes/youlikedprofile", title:"You Liked"},
    {href:"/likes/youlikedpics", title:"Their Pics"},    
  ]
  const location = useLocation();
  return (
    <JumpLinks isCentered>
      { 
        pageLinks.map(l => <JumpLinksItem key={l.href} href={l.href} isActive={location.pathname == l.href}>{l.title}</JumpLinksItem>)
      }       
    </JumpLinks>
  )
}

export { LikesJumpLinks };
