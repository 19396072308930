
import * as React from 'react';
import { Flex, HelperText, Stack, StackItem, TextArea } from '@patternfly/react-core';

interface ITextAreaEditor {
  focused: boolean;
  message: string;
  setMessage(message: string) : void;
  maxLength: number;
}

const TextAreaEditor = ({ focused = false, message, setMessage, maxLength = 4000}: ITextAreaEditor) => {
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null)

  React.useEffect(()=> {
    if (focused) {
      textAreaRef.current?.scrollIntoView({behavior: 'smooth'});

    //frmChatTextArea.current.scrollTo({ top: frmChatTextArea.current.scrollHeight });
    //$('#chat').scrollTop($('#chat')[0].scrollHeight);
    }
  }, [textAreaRef])
  
  return (
    <Flex className="TextAreaEditor" direction={{ default: 'column' }} >
      <style>
        {`
        chat { resize: none;overflow: hidden;min-height: 300px;max-height: 300px;}
        `}
      </style>
      <Stack>        
        <StackItem isFilled>
          <TextArea            
            ref={textAreaRef}
            style={{fontSize: "18px", fontWeight: "bold", border:"4px solid gray"}}
            placeholder='New Message'
            autoResize={true}
            value={message}
            maxLength={maxLength}
            onChange={(_event, value) => setMessage(value)}
            resizeOrientation="vertical"
            aria-label="text area vertical resize example"
            autoFocus={focused}
          />          
        </StackItem>
        <StackItem>
          <HelperText>{"(" + message?.length + "/" + maxLength + ")"}</HelperText>
        </StackItem>
      </Stack>
    </Flex>
  );
}
export { TextAreaEditor }