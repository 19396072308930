import { useApiClient } from '@app/Util/ApiClient';
import { Avatar, Split, SplitItem, StackItem } from '@patternfly/react-core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { MessageBubble } from './MessageBubble';
import { profilePicSteppedWidthApiUrl } from '@app/Util/AppUtil';
import { IMessage, IProfile, Like } from '@app/Util/AppData';

interface IList { 
  message: IMessage
  myProfileId: string
}

const ConversationsListPageItem: React.FunctionComponent<IList> = ({myProfileId, message}) => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  var m = message
  var conversantId = m.senderProfileId == myProfileId ? m.recipientProfileId : m.senderProfileId

  const { status, data: profile, error, isLoading } = useQuery({
    queryKey: ["profileGetById", conversantId],
    queryFn: () => apiClient.profileGetById(conversantId),
    staleTime: 60 * 60 * 1000, //1 hour
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    placeholderData: {id: "0", profilename: "...", profilePicIds: ["1"], characteristics:[ ], description: "", age: 21, height: "", weight: "", tsUpdated: new Date()}
  });

  let p = profile
  if (!p) return <></>

  return (    
    <Link className="ConversationsListPageItem" to={"/profile?id=" + conversantId}  key={"with_" + conversantId}>
      <Split hasGutter={true} >
        <SplitItem className='LeftColumn'>
          <Avatar src={profilePicSteppedWidthApiUrl(p.profilePicIds[0], 150)} isBordered={false} alt={p.profilename} size='lg' />
        </SplitItem>
        <SplitItem isFilled>
          <MessageBubble profile_name={p.profilename} myProfileId={myProfileId} message={m} showSeen={true} showSender={true} />                                    
        </SplitItem>
      </Split>      
    </Link>
  )

}

export { ConversationsListPageItem };
