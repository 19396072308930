import * as React from 'react';
import { Bullseye, Button, Gallery, GalleryItem, PageSection } from '@patternfly/react-core';
import { useMeasure } from '@react-hookz/web';
import { useIntersectionObserver } from '@uidotdev/usehooks';

interface ITile {
  imageUrlOfWidth: (width: number) => string
  onClick?: () => void
  bottomRightLabel?: string
  topRightLabelText?: string
}

const Tile = ({ imageUrlOfWidth, onClick=()=>{}, bottomRightLabel="", topRightLabelText="" }: ITile) => {
  const [show, setShow] = React.useState(true);
  const [m, ref] = useMeasure<HTMLDivElement>()
  const width = m?.width

  const styleLabelBottomLeft: React.CSSProperties = {
    display: "block", position: 'absolute', bottom: ".5em", left: ".5em", color: "white", fontSize: "10pt", fontWeight: "bolder", maxWidth: "85%", overflow: "hidden",
    textOverflow: "ellipsis", backgroundColor: "rgba(0,0,0,0.4)", borderRadius: "1em", padding: "0em .5em"
  }
  const styleLabelTopRight: React.CSSProperties = {
    display: "block", position: 'absolute', top: ".5em", right: ".5em", color: "white", fontSize: "10pt", fontWeight: "bolder", maxWidth: "85%", overflow: "hidden",
    textOverflow: "ellipsis", backgroundColor: "rgba(0,0,0,0.4)", borderRadius: "1em", padding: "0em .5em"
  }
  const styleImage: React.CSSProperties = { display: "block", position: "absolute", objectFit: "cover", height: width, width: width }

  return (
    <div className="Tile" ref={ref} style={{ minHeight: "2em", position: "relative", width: "100%", height: width }}>
      {
        show && width
          ? <div className="ProfileTile" style={{ display: "block", position: 'absolute', width: width, height: width, overflow: "none" }} onClick={onClick}>
            <img className="fit" src={imageUrlOfWidth(width)} loading='lazy'
              style={styleImage}
            />
            <div style={styleLabelBottomLeft}>{bottomRightLabel}</div>
            <div style={styleLabelTopRight}>{topRightLabelText}</div>
          </div>
          : <div></div>
      }
    </div>
  )
}

const TilesGrid = ({ tiles, requestMore, hasNextPage }: { tiles: ITile[], requestMore: () => void, hasNextPage: boolean }) => {
  const [loadMoreElement, entry] = useIntersectionObserver({ threshold: 0, root: null, rootMargin: "50%" });
  React.useEffect(() => {
    if (entry) {
      console.log("Ratio crossing: " + entry.intersectionRatio)
      if (entry?.intersectionRatio > 0) {
        requestMore()
      }
    }
  }, [entry])
  const widths = { default: "33%", sm: '33%', md: '25%', lg: '20%', xl: '10%', "2xl": '10%' }
  return (
    <div className="TilesGrid" style={{ position: "relative", margin: 0, padding: 0, height: "100%", width: "100%" }}>

      <Gallery className='ProfileTiles' minWidths={widths} maxWidths={widths}>
        <style> .ProfileTile .fit {"{"} {"}"} </style>
        {
          tiles.map((tile, i) =>
            <GalleryItem key={i}>
              <Tile
                imageUrlOfWidth={tile.imageUrlOfWidth}
                onClick={tile.onClick}
                topRightLabelText={tile.topRightLabelText}
                bottomRightLabel={tile.bottomRightLabel}
              />
            </GalleryItem>
          )
        }

        <GalleryItem>
          <Bullseye>
            {
              hasNextPage
                ? <div style={{ textAlign: "center" }} ref={loadMoreElement}>
                  Aren't you particular!<br />
                  <Button onClick={() => { requestMore() }}>Load More</Button>
                </div>
                : <div style={{ textAlign: "center" }} > You've had enough, calm down now.</div>
            }
          </Bullseye>
        </GalleryItem>

      </Gallery>

    </div>
  )
}

export { ITile, Tile, TilesGrid };
