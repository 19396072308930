import * as React from 'react';
import { useApiClient } from '../Util/ApiClient';
import { IProfile, allowedProfileCharacteristics } from '../Util/AppData';
import { ActionGroup, Alert, Bullseye, Button, Checkbox, DatePicker, Form, FormGroup, FormHelperText, HelperText, PageSection, TextArea, TextInput, Title } from '@patternfly/react-core';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { ProfilePictureUploadWidget } from './ProfilePictureUpload';
import { ProfilePicThumbs } from './ProfilePicThumbs';
import { ProfilePicReorderFrame } from './ProfilePicReorderFrame';

const DESC_LENGTH_MAX = 4000;
const THUMB_WIDTH = 80

const ProfileEditPage: React.FunctionComponent = () => {
  const [profile, setProfile] = React.useState<IProfile>()
  const [saveMessage, setSaveMessage] = React.useState<React.ReactElement|string>("")
  const [showUpload, setShowUpload] = React.useState(true)
  const descRef = React.useRef();

  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const history = useHistory();

  const QUERY_KEY = "PROFILE_MY"

  const { status, data, error, isLoading } = useQuery<IProfile, Error>({
    queryKey: [QUERY_KEY],
    queryFn: apiClient.profileGet,
    refetchOnWindowFocus: false,
    refetchOnMount: true,
    enabled: !profile
  });

  React.useEffect(() => {    
    setProfile(data)
  }, [data])

  const {mutate: saveProfile} = useMutation({
    mutationFn: apiClient.profilePut,
      onSuccess: () => {
        queryClient.invalidateQueries({queryKey: [QUERY_KEY]})
        setSaveMessage(<Alert variant='success' title="Saved" />)
    },
  });

  let createProfileCharCheckboxes = (p: IProfile, chars: string[]) => chars.map((char) => <CharCheckbox key={char} profile={p} characteristicLabel={char}/> );

  const CharCheckbox = ({profile, characteristicLabel} : {profile: IProfile, characteristicLabel: string}) => {
    const characteristicKey = characteristicLabel.toLowerCase();
    const onChange = (event: React.FormEvent<HTMLInputElement>, checked: boolean) => {
      if (profile) {
        let arr: string[] = profile.characteristics;
        arr = arr.filter(v => characteristicKey != v);
        if (checked) {
          arr.push(characteristicKey);
        }
        setProfile({ ...profile, characteristics: arr });
      }
    } 
    const selected = profile.characteristics.includes(characteristicKey);
    //console.log({chars:p.characteristics.join(","), selected, characteristicKey})
    return (<Checkbox key={characteristicKey} isChecked={selected} onChange={onChange} label={characteristicLabel} id={"alt-form-checkbox-" + characteristicKey} name={"alt-form-checkbox-" + characteristicKey} />);
  }

  const profilePicCss : React.CSSProperties = { position: 'relative', width: "5em", height: "7em", border: "1px solid gray" }

  const UploadThumb: React.FunctionComponent = () => (
    <div style={profilePicCss}>
      <Bullseye
        onClick={() => 
          //history.push("/profilePicUpload")
          setShowUpload(true)
        }
        style={{ height: "100%", width: "100%", position: "absolute", cursor: "pointer" }}>
        Upload
      </Bullseye>
    </div>
  )

  const dateFormat = (date: Date) => date ? date.toISOString().substring(0, 10) : "";
  const dateParse = (dateStr: string) => {
    const split = dateStr.split('-');
    if (split.length !== 3) {
      return new Date();
    }
    const year = split[0];
    const month = split[1];
    const day = split[2];
    return new Date(`${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T00:00:00`);
  };

  
  const p = profile
  console.log(p?.profilePicIds)
  return (
    isLoading
      ? <Title headingLevel='h2'>{status}</Title>
      : <PageSection>
        <Title headingLevel="h1" size="lg">
          Profile Editor
        </Title>
        {!p
          ? <>Not P</>
          :
          <Form isHorizontal>
            <FormGroup
              label="Public Profile Name"
              isRequired
              fieldId="horizontal-form-profilename"              
            >
              <TextInput
                value={p.profilename}
                isRequired
                type="text"
                id="horizontal-form-profilename"
                aria-describedby="horizontal-form-profilename-helper"
                name="horizontal-form-profilename"
                onChange={(e,v) => setProfile({ ...p, profilename: v })}
              />
            </FormGroup>
            <FormGroup label="Pictures" fieldId="horizontal-form-pictures">
              {/* <ProfilePicThumbs profilePicIds={p.profilePicIds} width={THUMB_WIDTH} profile={p} /> */}
              { p.profilePicIds ? <FormHelperText>Select, then drag and drop to reorder</FormHelperText> : <></> }
              <ProfilePicReorderFrame profilePicWidth={THUMB_WIDTH} profilePicIds={p.profilePicIds} setProfilePicIds={
                (profilePicIds) => {
                  //console.log("New Profile Pic Ids", profilePicIds)       
                  let p = {...profile, profilePicIds: profilePicIds }           
                  saveProfile(p, {
                    onSuccess: (data) => {setSaveMessage(<Alert variant="success" title="Saved Image Changes" />)}, 
                    onError: (error) => {setSaveMessage(<Alert variant="warning" title="Could not replace images..."/>)} 
                  });
                  setProfile(p)
                }                  
              } />
              
            </FormGroup>
            { showUpload && 
            <FormGroup label="Pic Upload" fieldId="horizontal-form-picture-upload">
              <ProfilePictureUploadWidget onSuccess={() => queryClient.invalidateQueries({queryKey: [QUERY_KEY]})} width={THUMB_WIDTH}></ProfilePictureUploadWidget>
            </FormGroup>
            }
            <FormGroup label="Birthdate" fieldId="horizontal-form-birthdate">
              <DatePicker
                value={p.birthdate instanceof Date ? dateFormat(p.birthdate) : p.birthdate ? p.birthdate : ""}
                //isRequired
                type='string'
                id="horizontal-form-birthdate"
                name="horizontal-form-birthdate"
                onChange={(str,dateval) => {
                  console.log("setting " + dateval);
                  setProfile({ ...p, birthdate: dateParse(dateval), age: (new Date().getFullYear() - dateParse(dateval).getFullYear()) })
                }}
                dateFormat={dateFormat} dateParse={dateParse}
              />
              &nbsp;&nbsp;Age: {moment().diff(p.birthdate, 'years')}
            </FormGroup>
            <FormGroup label="Height" fieldId="horizontal-form-height">
              <TextInput
                value={p.height}
                //isRequired
                type="text"
                id="horizontal-form-height"
                name="horizontal-form-height"
                onChange={(e,v) => setProfile({ ...p, height: v })}
              />
            </FormGroup>
            <FormGroup label="Weight" fieldId="horizontal-form-weight">
              <TextInput
                value={p.weight}
                //isRequired
                type="text"
                id="horizontal-form-weight"
                name="horizontal-form-weight"
                onChange={(e,v) => setProfile({ ...p, weight: v })}
              />
            </FormGroup>
            {
              allowedProfileCharacteristics.map((charGroup, index) => 
                <FormGroup
                  key={"char_" + charGroup.category}
                  label={charGroup.category}
                  //isStack
                  isInline
                  fieldId="horizontal-form-checkbox-group"
                  hasNoPaddingTop
                  role="group"
                >
                  {createProfileCharCheckboxes(p, charGroup.chars)}
                </FormGroup>
              )
            }            
            <FormGroup label="Narrative" fieldId="horizontal-form-exp" >
              <TextArea
                style={{ minHeight: "9em" }}
                defaultValue={p.description}
                maxLength={DESC_LENGTH_MAX}
                autoResize
                onChange={(e,v) => setProfile({ ...p, description: v })}
                id="horizontal-form-exp"
                name="horizontal-form-exp"
              />
              <HelperText>{"Describe what you want to convey to others.  (" + p.description?.length + "/" + DESC_LENGTH_MAX + ")"}</HelperText>
            </FormGroup>
            { saveMessage && <FormGroup>{saveMessage}</FormGroup> }
            <ActionGroup>              
              <Button variant="primary" onClick={() => saveProfile(p)}>Save</Button>
              <Button variant="link" onClick={() => window.location.reload() }>Reset</Button>
            </ActionGroup>
          </Form>
        }
      </PageSection>
  )
}

export { ProfileEditPage };

