import { Bullseye, Spinner } from '@patternfly/react-core';
import parse from 'html-react-parser';
import Cookies from 'js-cookie';
import React, { useCallback, useEffect, useState } from 'react';
import { ILocationReporting } from './LocationReporting';
import { profilePicUrl } from './ApiClient';

/**
 * Use window.sessionStorage to store session for page duration
 * Note: This is not localstorage which has unlimited lifetime
 */
let session = {
  set: function (key: string, value: string) { sessionStorage.setItem(key, value); },
  get: (key: string) => { return sessionStorage.getItem(key); },
  remove: (key: string) => { sessionStorage.removeItem(key); },
  clear: () => { sessionStorage.clear(); }
}

//let ageSinceDob = (dateOfBirth: any) : number => moment().diff(dateOfBirth, 'years');

let html = {
  encode: (plaintext: string) => plaintext ? parse(plaintext.trim()) : "",
  encodeP: (plaintext: string) => plaintext ? parse(plaintext.split("\n").map(p => "<p>" + p + "</p>").join("")) : "",
}

let base64 = {
  decode: (str: string): string => Buffer.from(str, 'base64').toString('binary'),
  encode: (str: any): string => Buffer.from(str, 'binary').toString('base64')
}

export const profilePicSteppedWidthApiUrl = (id: string, width: number) => {
  //let w = Math.ceil(width / 10) * 10
  const allowed = [32, 64, 128, 256, 512, 1024, 2048]
  let w = allowed.find((v) => v >= width)
  if (w == null) w = allowed[allowed.length - 1]
  return profilePicUrl + "/" + id + "?scale_x=" + w
}

const iterateArrayFrom = (startProfilePicId, profilePicIds) => {
  const startIndex = profilePicIds.indexOf(startProfilePicId)
  const profilePicIdsPart1 = profilePicIds.slice(startIndex)
  return [...profilePicIdsPart1, ...profilePicIds.slice(0, startIndex)]
}

// a custom hook for setting the page title
function useDocumentTitle(title: string) {
  useEffect(() => {
    const originalTitle = document.title;
    document.title = title;

    return () => {
      document.title = originalTitle;
    };
  }, [title]);
}

const GoogleMapsLink = ({ locationReporting }: {locationReporting: ILocationReporting}) => {
  const ll = locationReporting.reportingLatLng
  if (!ll) return <></>
  return (<a href={"https://maps.google.com/maps?z=12&t=m&q=loc:" + ll.lat + "+" + ll.lng} title={locationReporting.toString()} >Google Maps Link</a>)
}

export type UseCookieState = string | undefined;
const useCookie = (key: string, options: Cookies.CookieAttributes = {}, defaultValue?: string, pollIntervalMs: undefined | number = undefined) => {
  const [cookieValue, setCookieValue] = useState<UseCookieState>(Cookies.get(key));
  const [nextUpdateScheduled, setNextUpdateScheduled] = useState(0)

  useEffect(() => {
    const getStoredValue = () => {
      const raw = Cookies.get(key);
      if (raw !== undefined && raw !== null) {
        return raw;
      }
      else {
        if (defaultValue === undefined) { Cookies.remove(key); }
        else { Cookies.set(key, defaultValue, options); }
        return defaultValue;
      }
    };

    setCookieValue(getStoredValue());

    if (pollIntervalMs) {
      setTimeout(() => { setNextUpdateScheduled(Date.now) }, pollIntervalMs)
      console.log("Scheduled Next Cookie update")
    }
  }, [defaultValue, key, options]);

  const updateCookie = useCallback(
    (
      newValue: UseCookieState | ((prevState: UseCookieState) => UseCookieState),
    ) => {
      const value = newValue;
      if (value === undefined) { Cookies.remove(key); }
      else { Cookies.set(key, value, options); }
      setCookieValue(value);
    },
    [key, options],
  );

  const refreshCookie = useCallback(() => {
    const cookieValue = Cookies.get(key);
    console.log("Got Cookie " + key, cookieValue)
    setCookieValue(cookieValue);
  }, [key]);

  const deleteAllCookies = useCallback(() => {
    // var allCookies = document.cookie.split(';'); 
    // // The "expire" attribute of every cookie is set to "Thu, 01 Jan 1970 00:00:00 GMT" 
    // for (var i = 0; i < allCookies.length; i++) {
    //   const c = allCookies[i] + "=;expires=" + new Date(0).toUTCString()
    //   document.cookie = c
    //   console.log("Set Cookie to Delete as : ", c)
    // }          
    document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
    refreshCookie()
  }, []);

  //console.log("rendered useCookie")

  return { value: cookieValue, setValue: updateCookie, refresh: refreshCookie, deleteAllCookies } as const;
}

//Adapted from https://www.geodatasource.com/developers/javascript LGPL
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
//:::  This routine calculates the distance between two points (given the     :::
//:::  latitude/longitude of those points).                                   :::
//:::  FYI  South latitudes are negative, east longitudes are positive        :::
//:::                                                                         :::
//:::    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  :::
//:::    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  :::
//:::    unit = the unit you desire for results                               :::
//:::           where: 'M' is statute miles (default)                         :::
//:::                  'K' is kilometers                                      :::
//:::                  'N' is nautical miles                                  :::
//:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
function distanceBetween(lat1: number, lon1: number, lat2: number, lon2: number, unit: "M" | "K" | "N") {
  if ((lat1 == lat2) && (lon1 == lon2)) {
    return 0;
  }
  else {
    var radlat1 = Math.PI * lat1 / 180;
    var radlat2 = Math.PI * lat2 / 180;
    var theta = lon1 - lon2;
    var radtheta = Math.PI * theta / 180;
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit == "K") { dist = dist * 1.609344 }
    if (unit == "N") { dist = dist * 0.8684 }
    return dist;
  }
}


// distance between two geographical points using spherical law of cosines approximation
const distance = (latlng1, latlng2) => {
  const wrapLng = [-180, 180]
  // Mean Earth Radius, as recommended for use by
  // the International Union of Geodesy and Geophysics,
  // see https://rosettacode.org/wiki/Haversine_formula
  const R = 6371000
  var rad = Math.PI / 180,
    lat1 = latlng1.lat * rad,
    lat2 = latlng2.lat * rad,
    sinDLat = Math.sin((latlng2.lat - latlng1.lat) * rad / 2),
    sinDLon = Math.sin((latlng2.lng - latlng1.lng) * rad / 2),
    a = sinDLat * sinDLat + Math.cos(lat1) * Math.cos(lat2) * sinDLon * sinDLon,
    c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c;
}


const colloquialDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
  // TODO figure out whcih formula is btter and why are they so different 
  //const distance_m = new LatLng(lat1, lat2).distanceTo(new LatLng(lat2, lon2))
  //const distance_km = distanceBetween(lat1, lon1, lat2, lon2, "K")
  //console.log([distance_m / 1000, distance_km])
  const distance_mi = distanceBetween(lat1, lon1, lat2, lon2, "M")
  const distance = (distance_mi < 1) ? "" + Math.floor(distance_mi * 5280) + " ft" : "" + distance_mi.toFixed(2) + " mi"
  return distance
}

export function geolocationPositionErrorMessage(e: GeolocationPositionError|undefined) {
  if (e === undefined) return ""
  if (e?.code == e?.PERMISSION_DENIED) return "Geolocation denied, your device refused to provide location"
  if (e?.code == e?.POSITION_UNAVAILABLE) return "Geolocation unable to provide location"
  if (e?.code == e?.TIMEOUT) return "Geolocation timed out"
  return "OTHER ERROR"
}

const AppSpinner = () => <Bullseye><Spinner diameter="80px" aria-label="Loading" /></Bullseye>

/*
 Red: #E40303.
Orange: #FF8C00.
Yellow: #FFED00.
Green: #008026.
Indigo: #24408E.
Violet: #732982.
*/
const RainbowText = ({ text, color }: { text: string, color?: undefined | "dark" }) => {
  //Made brighter with monochromatic brightness selection
  //https://www.colorhexa.com/708ddb
  const rb_colors_bright = [
    "#E40303",
    "#FF8C00",
    "#FFED00",
    "#00cd3d", //"#008026",
    "#708ddb", //"#335ccb", //"#24408E", 
    "#c271d2"  //"#a63bbc", //"#732982"
  ]
  const rb_colors_dark = [
    "#E40303",
    "#FF8C00",
    "#b3a600", //"#e6d500", //"#FFED00",
    "#008026",
    "#24408E",
    "#732982"
  ]

  const rb_colors = (color == "dark") ? rb_colors_dark : rb_colors_bright

  let nextColorindex = 0
  const letters = text.split("").map((d, index) => <span key={d + index} style={{ color: rb_colors[nextColorindex++ % rb_colors.length] }}>{d}</span>)
  return (
    <span>{letters}</span>
  )

  const useContainerDimensions = myRef => {
    const [dimensions, setDimensions] = React.useState({ width: 0, height: 0 })
    React.useEffect(() => {
      const getDimensions = () => ({ width: myRef.current.offsetWidth, height: myRef.current.offsetHeight })
      const handleResize = () => { setDimensions(getDimensions()) }
      if (myRef.current) { setDimensions(getDimensions()) }
      window.addEventListener("resize", handleResize)
      return () => { window.removeEventListener("resize", handleResize) }
    }, [myRef, myRef.current])
    return dimensions;
  };
}

const Zoom = () => {
  useEffect(() => {
    const initialValue = document.body.style["zoom"];

    // Change zoom level on mount
    document.body.style["zoom"] = "150%";

    return () => {
      // Restore default value
      document.body.style["zoom"] = initialValue;
    };
  }, []);
  return <></>;
};

export { AppSpinner, GoogleMapsLink, RainbowText, base64, colloquialDistance, distanceBetween, html, iterateArrayFrom, session, useCookie, useDocumentTitle };

