import { AppStateContext } from "../../Util/AppState";
import { LocationReportingContext } from "@app/Util/LocationReportingContext";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useApiClient } from "../../Util/ApiClient";
import { IProfile, IProfileMapMarkerProps } from "../../Util/AppData";
import { List } from "@patternfly/react-core";

const useMapMarkerProfilesWithMyMarker = ({profileMapMarkersPropsArr} : {profileMapMarkersPropsArr: IProfileMapMarkerProps[]}) => {
  const appState = React.useContext(AppStateContext)
  const locationReporting = React.useContext(LocationReportingContext)
  const myProfileId = appState.settings?.defaultProfileId
  const apiClient = useApiClient()
  const {isLoading: myProfileLoading, data: myProfile} = useQuery<IProfile>({
    queryKey: ["profileGet", myProfileId],
    queryFn: () => apiClient.profileGetById("" + myProfileId),
    enabled: myProfileId != undefined
  })

  //console.log(profileMapMarkersPropsArr)

  let profileMapMarkersPropsMap : Map<string, IProfileMapMarkerProps> = (
    profileMapMarkersPropsArr
    .map(item => item as IProfileMapMarkerProps) // Type assertion to convert to IProfileMapMarkerProps
    .reduce(
      (map, mm) => {
        // console.log(mm)
        map.set("" + mm.profileId, mm);
        return map;
      }, 
      new Map<string, IProfileMapMarkerProps>()
    )
  )
  //console.log(profileMapMarkersPropsMap)

  //Always add my marker with data from myProfile if not included with location from this client, regardless of what comes from the server
  let myPmmp = profileMapMarkersPropsMap?.get("" + myProfileId) as unknown as IProfileMapMarkerProps    
  if (!myProfileLoading && myProfile && locationReporting.reportingLatLng && locationReporting.reportingLatLng.lat) {
    if (!myPmmp) {
      //We must looking at the map with our marker not within the bounds
      myPmmp = { profileId: myProfile.id, profileName: myProfile.profilename, profileAge: myProfile.age, profileChars: myProfile.characteristics, profileWeight: myProfile.weight, profileHeight: myProfile.height, profilePicIds: myProfile.profilePicIds, lat: 0, lon: 0, tsUpdated: new Date()}
      profileMapMarkersPropsMap.set("" + myProfileId, myPmmp)
    }
    const lrll = locationReporting.reportingLatLng
    //console.log(lrll)
    if (lrll.lat != myPmmp.lat || lrll.lng != myPmmp.lon) {
      //console.log("MyMapMarker outofsync ", [myPmmp.lat, lrll.lat, myPmmp.lon, lrll.lng])
      //locationReporting.updateServer()
      //Assert local position
      myPmmp.lat = lrll.lat
      myPmmp.lon = lrll.lng      
    } else {
      //console.log("MyMapMarker insync    ", [myPmmp.lat, lrll.lat, myPmmp.lon, lrll.lng])
    }           
  }
  
  //console.log(Array.from(profileMapMarkersPropsMap.entries()).filter(d =>  d[1]))

  return profileMapMarkersPropsMap
}

export { useMapMarkerProfilesWithMyMarker };

