import { useInfiniteQuery } from "@tanstack/react-query";
import { useApiClient } from "../Util/ApiClient";
import { LocationReportingContext } from '../Util/LocationReportingContext';
import { IProfileMapMarkerProps } from "../Util/AppData";
import { colloquialDistance, distanceBetween } from "../Util/AppUtil";
import { useContext, useEffect, useState } from "react";

const useNearestMapMarkers = ({pageSize} : {pageSize: number}) => {
  const locationReporting = useContext(LocationReportingContext)
  if (!locationReporting) return {}
  const [myLoc, setMyLoc] = useState(locationReporting.reportingLatLng)
  const apiClient = useApiClient();
  
  useEffect(()=> {
    if (!myLoc) setMyLoc(locationReporting.reportingLatLng)
  },[locationReporting.reportingLatLng])

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["mapMarkersNearestGet", myLoc],
    initialPageParam: 0,
    queryFn: ({pageParam = 0}) => {
      //console.log([data, pageParam])
       const x = apiClient.mapMarkersNearestGet(pageParam, pageSize).then((profileMapMarkerPropsArray) => {
        return {
          profileMapMarkerPropsArray,
          nextPageParam: profileMapMarkerPropsArray.length ? pageParam + 1 : undefined,
          prevPageParam: pageParam > 0 ? pageParam - 1 : undefined
        }        
      })
      return x;  
    },
    getNextPageParam: (lastPage, pages) => lastPage.nextPageParam,
    getPreviousPageParam: (firstPage, pages) => firstPage.prevPageParam    
  })

  const mapMarkers = ((myLoc)=>{
    let items : IProfileMapMarkerProps[] = []
    if (data) {
      data.pages.map((page, pageNum) => {
        //console.log(page)
        const mapMarkers = page.profileMapMarkerPropsArray
        items = [...items, ...mapMarkers]
      })
    }
    //console.log(items)
    const distanceTo = (a: IProfileMapMarkerProps) => distanceBetween(myLoc.lat, myLoc.lng, a.lat, a.lon, "M") 
    const collDist = (a: IProfileMapMarkerProps) => colloquialDistance(myLoc.lat, myLoc.lng, a.lat, a.lon)

    const mmMap = new Map<string, { profileMapMarkerProperties: IProfileMapMarkerProps, distance: number, collDist: string}>()
    //Make Unique
    items.forEach((pmmp) => {
      if (!mmMap.has(pmmp.profileId)) {
        mmMap.set(pmmp.profileId, { 
          profileMapMarkerProperties: pmmp, 
          distance: distanceTo(pmmp),
          collDist: collDist(pmmp)
        })
      }
    });
    const retVals = [...mmMap.values()].sort((a, b) => (a.distance - b.distance));
    //.sort((a,b) => (a.distance - b.distance))    
    //return retVals.map(a => a.profileMapMarkerProperties)    
    return retVals
  })(myLoc);

  return { data, fetchNextPage, isLoading, isFetching, status, mapMarkers, hasNextPage }
}

export { useNearestMapMarkers }