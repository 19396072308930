import React, { MouseEventHandler, ReactElement } from 'react';
import {
  Form,
  FormGroup,
  TextInput,
  ActionGroup,
  Button,
  HelperText,
  AlertVariant,
  Alert,  
} from '@patternfly/react-core';
import { TextAreaEditor } from '@app/Widgets/TextAreaEditor';
import { useApiClient } from '@app/Util/ApiClient'

export const ContactUsForm: React.FunctionComponent = (props) => {
  const [name, setName] = React.useState('');
  const [message, setMessage] = React.useState('');
  const [statusMessage, setStatusMessage] = React.useState<String|ReactElement>('');
  const [showForm, setShowForm] = React.useState(true);
    
  const apiClient = useApiClient()

  const handleNameChange = (name: string) => {
    setName(name);
  };

  const handleMessageChange = (message: string) => {
    setMessage(message)
  };

  const handleMouseEvent = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setShowForm(false)
    setStatusMessage(<Alert variant='info' title="Sending..." />)
    apiClient.contactUsMessagePost({name, message}).then((d) =>{      
      setStatusMessage(<Alert variant='success' title="Message Sent, you may receive a reply to the email address asscoiated with your account." />);
    }).catch((e) => {
      setStatusMessage(<Alert variant='danger' title="Message sending failed, please try again another time." />);      
    })
  };

  return (
    <>
    {showForm && 
    <Form isHorizontal>
      <FormGroup
        label="Full name"
        isRequired
        fieldId="horizontal-form-name"
      >
        <HelperText>Please provide your full name</HelperText>
        <TextInput
          value={name}
          isRequired
          type="text"
          id="horizontal-form-name"
          aria-describedby="horizontal-form-name-helper"
          name="horizontal-form-name"
          onChange={(e, v) => handleNameChange(v)}
        />
      </FormGroup>
      <FormGroup label="Message" fieldId="horizontal-form-exp">
        <TextAreaEditor focused={false} message={message} setMessage={setMessage} maxLength={4000} ></TextAreaEditor>        
      </FormGroup>
      <ActionGroup>
        <Button variant="primary" onClick={handleMouseEvent}>Submit</Button>                     
      </ActionGroup>
    </Form>
    }
    {statusMessage}
    </>
  );
};
