import { useApiClient } from '../Util/ApiClient';
import { ILatestMessageBetweenMeAndOtherProfiles } from '../Util/AppData';
import { Divider, NavItemSeparator, PageSection, Stack, StackItem, Title } from '@patternfly/react-core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import './ConversationsListPage.css'
import { ConversationsListPageItem } from './ConversationsListPageItem';

interface ICardList { }

const QUERY_KEY = ['conversations'];

const ConversationsListPage: React.FunctionComponent<ICardList> = (props) => {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const [saveMessage, setSaveMessage] = React.useState("");

  const { status, data, error, isFetching } = useQuery<ILatestMessageBetweenMeAndOtherProfiles, Error>({
    queryKey: QUERY_KEY, 
    queryFn: apiClient.latestMessageBetweenMeAndOtherProfilesGet, 
    refetchInterval: (query) => {       
      //TODO Implement backoff function
      return 180000
    },
    refetchOnMount: 'always'
  });

  return (
    <PageSection className="ConversationsListPage" height="100%" width="100%" style={{position:"relative",margin:0, padding:"1em"}}>
      {/* <Title headingLevel="h1" size="lg">
        Conversations
      </Title>      
      <Divider /> */}
      {
        isFetching || data === undefined
          ? "Loading..."
          :
          <Stack className='Conversations'>
            {
              !data.messages
              ? <StackItem style={{color: "white"}}>No conversations found</StackItem>
              : data.messages.map(m => {
                  //console.log(profile);
                  //console.log(message);                    
                  return (
                    <StackItem className="Item" key={m.id}>
                      <ConversationsListPageItem myProfileId={data.myProfileId} message={m} />
                      {/* <Divider inset={{default:"insetNone"}}/> */}
                    </StackItem>
                  )
                })
            }
          </Stack>
      }
    </PageSection>

  )
}

export { ConversationsListPage, QUERY_KEY as ConversationsQueryKey };
