import * as React from 'react';
import { Badge, Flex, FlexItem } from '@patternfly/react-core';
import { IProfile, Like } from '../Util/AppData';
import { LikeIcon } from './LikeIcon';
import { html } from '../Util/AppUtil';
import { ProfilePicThumbs } from './ProfilePicThumbs';
import { allowedProfileCharacteristics } from '../Util/AppData'
import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '@app/Util/ApiClient';

const PROFILE_PIC_THUMB_WIDTH_PX = 128

export const ProfileBrief = ({ profile, distanceStr: dist }: { profile: IProfile; distanceStr?: string; }) => {
  const apiClient = useApiClient()
  const { data: profileLikes } = useQuery({
    queryKey: ["profileLikesGet"],
    queryFn: apiClient.profileLikesGet,    
    placeholderData: []
  })

  let characteristics = profile.characteristics
  if (profile && profile.characteristics.length == 0) {
    characteristics = ["i'm", "so", "boring"]
  }
  return (
    <div className="ProfileBrief">
      <div>
        <Flex className='identity' style={{ fontSize: "18px", lineHeight: "1em" }} justifyContent={{ default: 'justifyContentSpaceBetween' }}>
          <FlexItem className='profilename' style={{ fontWeight: 'bolder' }}>
            {profile.profilename}
            {
              profileLikes?.find((l) => l.likedProfileId == profile.id && l.likeType == Like.LIKE) != undefined
              ? <LikeIcon likeType={Like.LIKE} selected={true} style={{ marginLeft: "1em" }} />
              : <></>
            }
          </FlexItem>
          <FlexItem className='stats'>
            {html.encode(
              (profile.height ? profile.height + " " : "") +
              (profile.weight ? profile.weight + " " : "") +
              (profile.age ? profile.age : "")              
            )}
            {
              dist 
              ? html.encode("&nbsp;&nbsp;&#x2192;&nbsp;&nbsp;") + dist 
              : ""
            }
          </FlexItem>
        </Flex>

        <hr style={{ margin: ".5em 0" }} />
        <ProfilePicThumbs profile={profile} profilePicIds={profile.profilePicIds} width={PROFILE_PIC_THUMB_WIDTH_PX} />
        <hr style={{ margin: ".5em 0" }} />
        
        <div className="characteristics">          
          {
            allowedProfileCharacteristics
            .filter(el => el.chars.filter(c => characteristics.includes(c.toLowerCase())).length > 0)
            .map((el, index, arr) => <>
              <>                
                {/* {el.chars ? el.category + ":" : <></>} */}
                {
                  el.chars.map((catname , i) => {
                    if (characteristics.includes(catname.toLowerCase()))
                      return <Badge key={catname.toLowerCase() + i} className={'characteristic ' + catname.toLowerCase()} >{catname}</Badge>
                    return <span key={catname.toLowerCase() + i}></span>
                  })
                }
              </>              
            </>).map((el, index, array) => <span key={"" + el.key + index}>{index == 0 ? <></> : <span className='separator'> | </span>}{el}</span>)
          }          
        </div>
      </div>
    </div>
  );
};
