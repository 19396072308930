import * as React from 'react';
import { Button, Card, CardBody, CardHeader, CardTitle, Checkbox, Dropdown, DropdownItem, DropdownList, Flex, FlexItem, Gallery, GalleryItem, Grid, GridItem, MenuToggle, MenuToggleElement, PageSection, Stack, StackItem, Text, Title, ToggleGroup, ToggleGroupItem } from '@patternfly/react-core';
import './HomePage.css';
import { DharmachakraIcon, EllipsisVIcon, HelpIcon, RegisteredIcon, TrashIcon, UserCogIcon, UserIcon, UsersCogIcon, UsersIcon, WrenchIcon } from '@patternfly/react-icons';
import { Diversity1, Games, Login, People } from '@mui/icons-material';
import {AppStateContext} from '../Util/AppState'
import { useHistory } from 'react-router-dom';
import { RainbowText } from '@app/Util/AppUtil';

interface ICardList { }

const HomePage: React.FunctionComponent<ICardList> = (props) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const appState = React.useContext(AppStateContext);
  
  React.useEffect(() => { setIsLoading(false); }, []);

  const cardData = (appState.auth.isLoggedIn) ? authCardsData : noAuthCardsData
  return (
    <PageSection className='HomePage'>
      <Title headingLevel="h1" size="lg">
      Welcome to {<RainbowText text={location.hostname} />}!
      </Title>
      <Text style={{}} component='p'>All the best of class features in one community supported and managed app.</Text>
      <Text style={{}} component='p'>&nbsp;</Text>
      <style>
        {
          `
          /*@media screen and (min-width: 400px) */
          .HomePage .pf-v6-l-grid.pf-m-all-6-col-on-sm > * {
              --pf-v6-l-grid__item--GridColumnEnd: span 6;
          }    

          .HomePage .pf-v6-c-card.pf-m-compact {
            height: 100%;
          }
          `
        }
      
      </style>
      {
        isLoading
          ? "Loading..."
          :
          <>
            <Grid               
              hasGutter
              sm={6} md={6} lg={4} xl={3} xl2={2}
            >
              {cardData.map((data, index) =>
                <GridItem key={data.id}>
                  <AppCard card={data} />
                </GridItem>
              )}
            </Grid>
          </>
      }
    </PageSection>

  )
}
const iconSize = "50px"
interface ICardData {
  id: number
  name: string
  description: string
  url: string
  icon: string | React.ReactElement
}

let id = 0
const authCardsData: ICardData[] = [
  {
    id: id++,
    name: 'Search by Map',
    description: 'See other users locations on the map.',
    url: "/search/map",
    icon: <DharmachakraIcon />    
  },
  {
    id: id++,
    name: 'Engagement',
    description:
      'Games and other community participation features.',
    url: "/games",
    icon: <Diversity1 fontSize='inherit'/>
  },
  {
    id: id++,
    name: 'Swype Tiles',
    description:
      'View other users pics in order of distance from you.',
    url: "/search/tile",
    icon: <>&#9638;</>
  },
  {
    id: id++,
    name: 'Read Profiles',
    description:
      'View full profiles of other users and send them messages.',
    url: "/search/profile",
    icon: <People fontSize='inherit'/>
  },
  {
    id: id++,
    name: 'Edit My Profile',
    description:
      'Update your public profile, images, and personal narrative.',
    url: "/profileEdit",
    icon: <UserCogIcon/>
  },
  {
    id: id++,
    name: 'Settings',
    description:
      'Update your user settings, email, password, and notifications options.',
    url: "/settings",
    icon: <WrenchIcon/>
  },
  {
    id: id++,
    name: 'About Us',
    description:
      'Learn about this site, its governance, and community.',
    url: "/aboutus",    
    icon: <HelpIcon/>
  },
]

const noAuthCardsData: ICardData[] = [
  {
    id: id++,
    name: 'Login',
    description: 'See other users locations on the map.',
    url: "/login",
    icon: <Login fontSize='inherit'/>
  },
  {
    id: id++,
    name: 'Register',
    description:
      'View other users pics in order of distance from you.',
    url: "/register",
    icon: <RegisteredIcon/>
  },
]


const AppCard = ({ card }: { card: ICardData }) => {
  const history = useHistory()

  return (
    <Card 
      isCompact 
      isClickable 
      isSelectable 
      key={card.name} 
      id={card.name.replace(/ /g, '-')}
      onClick={() => history.push(card.url)}
    >
      <CardHeader>
        <div style={{fontSize:iconSize}}>
          {card.icon}
        </div>        
      </CardHeader>
      <CardTitle>{card.name}</CardTitle>
      <CardBody>{card.description}</CardBody>
    </Card>)
}
export { HomePage };