import { ProfileCardLazy } from '../Profile/ProfileCard';
import { useApiClient } from '../Util/ApiClient';
import { IProfile, IProfileMapMarkerProps, allowedProfileCharacteristics, } from '../Util/AppData';
import { Bullseye, Button, Checkbox, ExpandableSection, Form, FormGroup, Gallery, GalleryItem, Grid, GridItem, PageSection, Spinner } from '@patternfly/react-core';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useNearestMapMarkers } from './MapMarkerData';
import { useIntersectionObserver } from '@uidotdev/usehooks';
import { AppSpinner } from '../Util/AppUtil';
import { ProfileFilter } from './ProfileFilter';

//TODO  Implement Inf Scrolling and Filtering 
//https://codesandbox.io/s/infinite-scrolling-useref-g4fgz?file=/src/App.js

interface IProfilesPage { }
const ProfilesPage: React.FunctionComponent<IProfilesPage> = (props) => {
  const [profiles, setProfiles] = React.useState<IProfile[]>([]);
  const [filtered, setFiltered] = React.useState(false);
  const [filterChars, setFilterChars] = React.useState<string[]>([])
  const queryClient = useQueryClient();
  const apiClient = useApiClient();
  const history = useHistory();

  const { mapMarkers, data, fetchNextPage, isFetching, isLoading } = useNearestMapMarkers({pageSize: 3})

  if (!fetchNextPage) return <></>

  const [loadMoreElement, entry] = useIntersectionObserver({ threshold: .25, root: null, rootMargin: "0%" });

  React.useEffect(() => {
    if (entry) {
      console.log(JSON.stringify([entry.time, entry.intersectionRatio]))
      if (entry?.intersectionRatio > 0 && !isFetching) {
        fetchNextPage()
        console.log("Fetchign more ->  intersection ratio : " + entry.intersectionRatio)
      }
    }
  }, [entry])

  console.log("Rendering", mapMarkers);

  return (
    <PageSection height="100%" width="100%" style={{ position: "relative", margin: 0, padding: 0 }}>
      <div style={{margin:"0 1.5em"}}>
        <ProfileFilter filterChars={filterChars} setFilterChars={setFilterChars} filtered={filtered} setFiltered={setFiltered} />      
      </div>
      {/* <>Rendered Search : { JSON.stringify({ts: Date.now(), isFetched, isLoading, dataLe: mapMarkers?.length}) }</> */}
      {isLoading || !mapMarkers
        ? <AppSpinner />
        :
        <Grid
          // style={{margin:"20px"}}
          sm={12} md={12} lg={6} xl={4} xl2={3}
          hasGutter
        >       
          {
            mapMarkers
              .map((mm, i) =>{
                const d = mm.profileMapMarkerProperties
                return (
                  <GridItem key={d.profileId}>
                    <ProfileCardLazy profileId={d.profileId} profileMapMarkerProps={d} showChatProfileButton={true} />
                  </GridItem>
                )
              })
          }
          <GridItem>
            <Bullseye>
              <div ref={loadMoreElement} style={{ textAlign: "center" }}>Aren't you particular!<br /><Button onClick={() => { !isFetching && fetchNextPage() }}>Load More</Button></div>
            </Bullseye>
          </GridItem>

        </Grid>
      }
    </PageSection>
  )
}

export { ProfilesPage };
