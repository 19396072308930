//import { parser } from 'html-react-parser';
import * as React from 'react';
import './ProfileCard.css';
import { Badge, Icon, Stack, StackItem, TextArea, TextContent } from '@patternfly/react-core';
import { IProfile, IProfileMapMarkerProps, Like } from '../Util/AppData';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../Util/ApiClient';
import { useHistory } from 'react-router-dom';
import { IProfileActionButtons, ProfileCardActionButtons } from './ProfileCardActionButtons';
import { useInView } from 'react-intersection-observer';
import { ExpandingPlaintextDiv } from '../Widgets/ExpandingPlaintextDiv';
import { colloquialDistance } from '../Util/AppUtil';
import { LocationReportingContext } from '../Util/LocationReportingContext';
import { ProfileBrief } from './ProfileBrief';
import { AppStateContext } from '../Util/AppState';

const thresholdsZeroToOneInTenths: number[] = [];
for (let i: number = 0; i <= 1; i += 0.1) {
  thresholdsZeroToOneInTenths.push(parseFloat(i.toFixed(2)));
}
//console.log(thresholds)
interface IProfileCard extends IProfileActionButtons {
  profileId: string
  showDescription?: boolean
  children?: React.ReactElement
  profileMapMarkerProps?: IProfileMapMarkerProps
}
const ProfileCardLazy = ({ profileId, showChatProfileButton = false, showLikeButtons = false, showNotes = false, showAction = false, showDescription = true, profileMapMarkerProps = undefined, children = undefined }: IProfileCard) => {
  const [show, setShow] = React.useState(false)
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: thresholdsZeroToOneInTenths,
    //triggerOnce: true,  //Not Working as expected only triggers at 1.0 fully visible threshold!!!!!
    //rootMargin: "30px 30px 30px 30px",
    //skip: show,
    //trackVisibility: true,
    onChange: (inView: boolean, entry: IntersectionObserverEntry) => {
      //if (!show) {console.log(entry.intersectionRatio) }
      if (entry.intersectionRatio > 0 && !show) { setShow(true) }
    }
  });

  return (
    // <div ref={ref}>
    //   <h2>{`Header inside viewport ${inView}.`}</h2>
    // </div>
    <span ref={ref} style={{
    }}>
      {
        //inView || 
        show
          ? <ProfileCardActive profileId={profileId} showChatProfileButton={showChatProfileButton} showLikeButtons={showLikeButtons} showNotes={showNotes} showAction={showAction} showDescription={showDescription} children={children} profileMapMarkerProps={profileMapMarkerProps} />
          : <span style={{ display: "block", minHeight: "100em" }}></span>
      }
    </span>

  );
}

const ProfileCardActive = ({ profileId, showLikeButtons = false, showChatProfileButton = false, showNotes = false, showAction = false, showDescription = true, children = undefined, profileMapMarkerProps }: IProfileCard) => {
  const apiClient = useApiClient()
  const queryClient = useQueryClient()
  const appState = React.useContext(AppStateContext)
  const myProfileId = appState.settings?.defaultProfileId
  const { data: profileLikes } = useQuery({
    queryKey: ["profileLikesGet", myProfileId],
    queryFn: apiClient.profileLikesGet,    
    initialData: [],
    staleTime: 60 * 60 * 1000  // 1 hour in ms
  })

  const locationReporting = React.useContext(LocationReportingContext)
  if (!locationReporting) return <></>

  const { status, data: profile, error, isLoading, isFetched } = useQuery({
    queryKey: ['profileGetById', profileId],
    queryFn: () => apiClient.profileGetById(profileId),
    refetchOnWindowFocus: true,
    staleTime: 60 * 60 * 1000, //1 hour
    enabled: profileId !== undefined,
    placeholderData: undefined
  });

  if (!profile) return <>Loading...</>
  
  let distanceStr: undefined | string = undefined
  if (profileMapMarkerProps) {
    const rll = locationReporting.reportingLatLng
    distanceStr = colloquialDistance(rll.lat, rll.lng, profileMapMarkerProps.lat, profileMapMarkerProps.lon)
  }

  const likeType = (
    profileLikes.filter((l) => l.likedProfileId == profile.id && l.likeType == Like.LIKE)[0] != undefined
    ? Like.LIKE
    : Like.UNDETERMINED
  )
  console.log("Determined " +  likeType)

  //console.log("RENDER Card " + profileId)
  return (
    <> {
      isLoading || !profile
        ? <>
          {/* {JSON.stringify({status, error, isLoading, isFetched})} */}
        </>
        : <Stack hasGutter className='ProfileCard'>
          <StackItem className='header' isFilled>
            <Stack className='ProfileCardHeaderStack'>
              <StackItem>
                <ProfileBrief profile={profile} distanceStr={distanceStr} />
              </StackItem>
              {
                showDescription &&                
                <StackItem>
                  <hr style={{ margin: ".5em 0" }} />

                </StackItem>
              }
              {
                showDescription &&
                <StackItem isFilled>
                  <ExpandingPlaintextDiv plaintext={profile.description} classname='description' maxHeight='13em' showMoreButtonText="Show More Biography" />
                </StackItem>
              }

            </Stack>
          </StackItem>
          <StackItem>
            <ProfileCardActionButtons
              profileId={profileId}
              //TODO remove LikeType from IProfile
              profileLikeType={likeType}
              showLikeButtons={showLikeButtons}
              showChatProfileButton={showChatProfileButton}
              showNotes={showNotes}
              showAction={showAction}
            />
          </StackItem>
          {children}
        </Stack>

    }

    </>

  )
}

export { ProfileCardLazy }