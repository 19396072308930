import * as React from 'react';
import { BackgroundImage, Button, PageSection, Split, SplitItem, Stack, StackItem, Title } from '@patternfly/react-core';
import { useHistory } from 'react-router-dom';
import { ProfilePictureUploadWidget } from './ProfilePictureUpload';
import Cropper, { Area } from 'react-easy-crop'
import getCroppedImg from '../Widgets/ImageCrop/cropImage';

const ProfilePictureEditPage: React.FunctionComponent = () => {

  const [alert, setAlert] = React.useState<React.ReactElement>()
  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [rotation, setRotation] = React.useState(0)
  const [zoom, setZoom] = React.useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState<Area>()
  const [croppedImageFile, setCroppedImageFile] = React.useState<File>()

  const history = useHistory()
  const queryParams = new URLSearchParams(window.location.search);
  const id: number = queryParams.get("id") ? Number(queryParams.get("id")) : 0

  const origImgUrl = "/api/profilepic/" + id + "?orig=1" 

  async function blobUrlToFile(blobUrl: string, fileName: string): Promise<File | null> {
    try {
      // Fetch the Blob data
      const response = await fetch(blobUrl);
      const blob = await response.blob();
  
      // Convert Blob to File
      const file = new File([blob], fileName, { type: blob.type });
      return file;
    } catch (error) {
      console.error('Error converting Blob URL to File:', error);
      return null;
    }
  }

  const getCroppedImageFile = async () => {
    try {
      const croppedImage : string = await getCroppedImg(
        origImgUrl,
        croppedAreaPixels,
        rotation
      )
      const file = blobUrlToFile(croppedImage, "croppedImage")
      return file
    } catch (e) {
      console.error(e)
      return null
    }
  }

  function post(file: File) {    
    const url = '/api/profilepic?replace_id=' + id;
    const formData = new FormData();
    if (file) {
      formData.append('picfile', file);
      formData.append('picfilename', file.name);
    }
    const config = {
      headers: {        
        //'Content-Type' : 'multipart/form-data',        
        //'Accept' : "application/json"
      },
      method : "POST",
      body: formData
    };

    fetch(url, config)
    .then((response) => {return response.status}, err => console.log(err))    
  }

  const onSave = () => {
    getCroppedImageFile().then(
      file => {
        if (file) {
          post(file)
          history.push("/profileEdit")
        }
        else console.log("Problem with ", file)
      },
      err => console.log(err)
    )
  }
  const onCancel = () => history.push("/profileEdit")

  return (
    <PageSection>
      <Stack>
        <StackItem>
        <Title headingLevel="h1" size="lg">
        Profile Picture Edit
      </Title>      
        </StackItem>
        <StackItem isFilled>
        <Cropper 
        style={{
          //mediaStyle:{width:"400px", height:"400px"},
          cropAreaStyle:{width:"100px", height:"200px"},
          //containerStyle:{width:"50%", height:"50%"}
        }}
        image={origImgUrl}
        crop={crop}
        rotation={rotation}
        zoom={zoom}
        aspect={3/4}
        onCropChange={setCrop}
        onRotationChange={setRotation}
        onCropComplete={(croppedArea, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels) }
        onZoomChange={setZoom}
      />
        </StackItem>
        
        {alert && <StackItem>{alert}</StackItem>}
        <StackItem>
          <Split>
            <SplitItem isFilled><Button onClick={onCancel} style={{width:"100%", fontWeight: "bold", color:"black"}}>Cancel</Button></SplitItem>
            <SplitItem isFilled><Button onClick={onSave} style={{width:"100%", fontWeight: "bold", color:"white", backgroundColor:"navy"}}>Save</Button></SplitItem>
          </Split>
        </StackItem>
      </Stack>
      {/* {pictures} */}
      
      
    </PageSection>
  );
}

export { ProfilePictureEditPage };