import { debounce } from '@patternfly/react-core';
import { useEffect, useMemo, useState } from 'react';

export interface ILocationDevice {
  geolocationPosition?: GeolocationPosition
  loading: boolean;  
  error?: GeolocationPositionError;  
  setPauseGpsLocation: (pause: boolean) => void
}

export const useLocationDevice = (options?: PositionOptions): ILocationDevice => {
  const [loading, setLoading] = useState(true);
  const [geolocationPosition, setGeolocationPosition] = useState<GeolocationPosition>()
  const [error, setError] = useState<GeolocationPositionError>()
  const [watchId, setWatchId] = useState<any>(null);
  const [pauseGpsLocation, setPauseGpsLocation] = useState(false);
  const [timerId, setTimerId] = useState<NodeJS.Timeout>()

  const notThatDifferent = (g1: GeolocationPosition|undefined, g2: GeolocationPosition, reportDiffAfterMs: number) => {
    if (g1 === undefined) return false
    if (g1.coords.latitude != g2.coords.latitude) return false
    if (g1.coords.longitude != g2.coords.longitude) return false
    if (Math.abs(g1.timestamp - g2.timestamp) > (reportDiffAfterMs)) return false
    console.log("Device Location is different", g1, g2)
    return true;
  }

  const onEvent = (glp :GeolocationPosition) => {
    if (!pauseGpsLocation) {
    //More than 10 SECONDS in Milliseconds different
    const REPORT_ATLEASTEVERY_SECS = 60
      if (notThatDifferent(geolocationPosition, glp, REPORT_ATLEASTEVERY_SECS * 1000)) {
        console.log("Location is not that different, skipping")
        clearTimeout(timerId)
        let tid = setTimeout(
          () => {
            setGeolocationPosition(glp)    
          },
          60 * 1000
        )
        setTimerId(tid)
      } else {
        console.log("Location is different, reporting device location", glp)
        clearTimeout(timerId)
        setGeolocationPosition(glp)
      }      
      setError(undefined)
      setLoading(false)
    }
  };

  const onError = (error: GeolocationPositionError) => { 
    if (!pauseGpsLocation) {
      console.log("LocationDevice error", error)
      setError(error)
      setLoading(false) 
    }
  }

  const onGeolocattionEvent = debounce(onEvent,  5000)
  //const onGeolocattionEvent = onEvent

  useEffect(() => {
    //console.log("Watching geolocation")
    setWatchId(navigator.geolocation.watchPosition(onGeolocattionEvent, onError, options))
    navigator.geolocation.getCurrentPosition(onGeolocattionEvent, onError, options)
    
    return () => {
      navigator.geolocation.clearWatch(watchId)
      //console.log("Watching geolocation cleared")
    };
  }, []);

  const retVal = useMemo(() => ({
    geolocationPosition,
    error,
    loading,
    setPauseGpsLocation
  })
  ,[
    geolocationPosition,
    error,
    loading,
    setPauseGpsLocation
  ])

  return retVal;
};
