import React from "react"

export const enum Like {LIKE = "LIKE", DISLIKE = "DISLIKE", UNDETERMINED = "UNDETERMINED", ERROR = "ERROR"}

export interface IVersion {
  gitCommitHash: string
	gitCommitTs: string
}

export interface ISettings {
  name: string
  email: string
  password: string
  defaultProfileId: string
  profiles: [IProfile]
  useGeoLocation: boolean 
  pushSubscriptions: IPushNotificationSubscription[]
  applicationServerKey: string
}

export interface IProfilePosition {
  profileId?: string
  overrideLat: number
  overrideLon: number
  gpsLat?: number
  gpsLon?: number
  gpsTimestamp?: Date
  hide: boolean
  use: "DEVICE_GPS"|"OVERRIDE";  
}

export interface IContactUsMessage {
  senderProfileId?: string
  name: string
  message: string
}

export interface IPushNotificationSubscription {
  deviceName : string
  endpoint: string
  expirationTime: number
  auth_base64: string
  p256dh_base64: string
  applicationServerKey: string
}

export interface IProfile {
  id: string
  profilename: string
  profilePicIds: string[]
  birthdate?: Date
  age: number
  height: string
  weight: string
  characteristics: string[]
  description : string
  tsUpdated : Date
}

export interface IProfileLike {
  likerProfileId: string
  likedProfileId: string
  likeType: Like
  tsCreated: Date  
}
export interface IProfileLikes extends Array<IProfileLike> {}

export interface IProfilePicLike {
  likerProfileId: string
  profilePicId: string
  profilePicProfileId: string
  likeType: Like
  tsCreated: Date  
}
export interface IProfilePicLikes extends Array<IProfilePicLike> {}

export interface IProfileNote {
  id: string
  authorProfileId: string
  aboutProfileId: string
  bodyText: string
  tsCreated: Date  
}

export interface IMessage {
  id?: string
  senderProfileId: string
  senderProfileName?: string
  recipientProfileId: string
  recipientProfileName?: string
  bodyText: string
  tsCreated: Date
  tsSenderDeleted?: Date
  tsRecipientRead?: Date
  tsRecipientDeleted?: Date
}

export interface IMessagesWith {
  myProfileId: string
  withProfileId: string
  messages: IMessage[]
}

export interface IConversation {
  profileId: string
  messages: {[key: string]: IMessage}
  latestMessageId: string
  latestMessageCreated: Date
}

export interface ILatestMessageBetweenMeAndOtherProfiles {
  myProfileId : string   
  messages: IMessage[] 
}

export interface IProfileMapMarkerProps {
  profileId : string
  profileName: string
  profileHeight: string
  profileWeight: string
  profileAge : number
  profileChars: string[]
  lat : number
  lon : number
  tsUpdated: Date
  profilePicIds : string[]  
}

export interface IProfilePositionAggregate {
  deltaLat: number
  deltaLon: number
  centerLat: number
  centerLon: number
  count: number
}

//let IProfileMapMarkers: Map<number,IProfileMapMarkerProps> = new Map<number,IProfileMapMarkerProps>();

//export interface IProfileMapMarkers extends Map<number,IProfileMapMarkerProps> 

export interface IProfileMapMarkers {
  [key:string]:IProfileMapMarkerProps
}

export const allowedProfileCharacteristics : {category:string, chars: string[]}[] = [
  {category: "Positions", chars: ["Top", "Bottom", "Versatile", "Oral"]}, 
  {category: "Safety", chars: ["SafeOnly", "Hiv+", "Hiv-", "PrEP", "DDF", "Tested", "Vax+"]}, 
  {category: "Body", chars: ["Hairy", "Smooth", "Masculine", "Feminine"]}, 
  {category: "Community", chars: ["Bear", "Leather", "BDSM", "Poz"]}, 
  {category: "Kink", chars: ["Fetish", "Taboo", "Kink", "FistTop", "FistBottom"]} 
];

  