import { AppStateContext } from '../Util/AppState';
import { ChatConversation } from '../Chat/ChatConversation';
import { ProfileCardLazy } from '../Profile/ProfileCard';
import { useApiClient } from '../Util/ApiClient';
import { IProfile } from '../Util/AppData';
import { PageSection, Stack, StackItem } from '@patternfly/react-core';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

interface IProfilePage { }
const ProfilePage: React.FunctionComponent<IProfilePage> = (props) => {
  const [profiles, setProfiles] = React.useState<IProfile[]>([]);
  const [chatFocused, setChatFocused] = React.useState(false);
  
  const queryParams = new URLSearchParams(window.location.search);
  const id: string = "" + queryParams.get("id")
  let ids: string[] = [];
  if (id) ids = [...ids, id];

  const appStateContext = React.useContext(AppStateContext)
  const myProfileId = appStateContext.settings?.defaultProfileId || ""

  const apiClient = useApiClient();
  const { status, data : profile, error, isLoading } = useQuery<IProfile, Error>({
    queryKey: ["profileGetById", id],
    queryFn: () => apiClient.profileGetById(id),
    staleTime: 60 * 60 * 1000, //1 hour    
    refetchOnWindowFocus: true
  });

  let footer = <></>

  let contents: any = null;
  if (!id) {
    contents = ["no id was specified"];
  } else {
    contents = isLoading || !profile
      ? "Loading..."
      :
      <Stack>
        <StackItem>
          <ProfileCardLazy 
            profileId={profile.id} 
            children={footer} 
            showLikeButtons={true}
            showNotes={true}
            showAction={true}
          />
        </StackItem>
        <StackItem>
          <ChatConversation myProfileId={myProfileId} profile={profile} focused={chatFocused}/>
        </StackItem>
      </Stack>
  }

  return (
    <PageSection height="100%" width="100%" style={{ position: "relative", margin: 0, padding: 0 }}>
      {contents}
    </PageSection>
  )
}

export { ProfilePage };
