import * as React from 'react';
import { ActionGroup, BackgroundImage, Button, Checkbox, Form, FormGroup, FormSelect, FormSelectOption, PageSection, Radio, TextArea, TextInput, Title, FileUpload, DropEvent } from '@patternfly/react-core';
import { useHistory } from 'react-router-dom';
import FileUploadIcon from '@patternfly/react-icons/dist/esm/icons/file-upload-icon';
import { PicThumbRaw, ProfilePicThumb } from './ProfilePicThumbs';
import { profilePicUrl } from '@app/Util/ApiClient';

const ProfilePictureUploadWidget = ({onSuccess, width} : {onSuccess(): void, width: number}) => {
  const [file, setFile] = React.useState<File>();
  const [filename, setFilename] = React.useState("");
  const [fileDataUrl, setFileDataUrl] = React.useState<string|null>()
  const [uploadMessage, setUploadMessage] = React.useState("")

  const history = useHistory();

  React.useEffect(()=> {
    if (file) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          // convert image file to base64 string
          setFileDataUrl(reader.result as string);
        },
        false,
      );
      reader.readAsDataURL(file);
    }    
  }, [file])

  const effect = React.useEffect(() => {
    if (file) {
      //post();
    }
  }, [file])

  const handleFileInputChange = (event: DropEvent, file: File) => {
    setFile(file);    
  }

  const handleClear = () => {
    setFilename('');
    setFile(undefined);
  }

  function post() {    
    const url = profilePicUrl;
    const formData = new FormData();
    if (file) {
      formData.append('picfile', file);
      formData.append('picfilename', file.name);
    }
    const config = {
      headers: {        
        //'Content-Type' : 'multipart/form-data',        
        //'Accept' : "application/json"
      },
      method : "POST",
      body: formData
    };

    let resp;
    fetch(url, config)
    .then((response) => {resp = response; return response.text()})
    .then(
      txt => { 
        handleClear()
        setUploadMessage("Uploaded")
        setTimeout(() => setUploadMessage(""), 2000)
        onSuccess()
        //history.push("/profileEdit")
      },
      err => console.error(err)      
    );
  }

  return (
    <>
      {file 
        ?
        <div className="pf-u-m-md">
          {/* <FileUploadIcon /> Custom preview here for your {file.size}-byte file named {file.name} */}
          {/* { fileDataUrl && <img id="profileImage" src={fileDataUrl}></img> } */}
          { fileDataUrl && <PicThumbRaw src={fileDataUrl} width={width} /> }    
          <Button onClick={post}>Upload</Button>      
        </div>
        : 
        <FileUpload
          id="customized-preview-file"
          value={file}
          filename={filename}
          filenamePlaceholder="Drag and drop a file or upload one"
          onFileInputChange={handleFileInputChange}
          onClearClick={handleClear}
          hideDefaultPreview={false}
          browseButtonText="Select"
          dropzoneProps={{
            // accept: {
            //   "image/*": [".png", ".gif", ".jpeg", ".jpg"],
            // },
            // accept: {
            //   "image/png": [".png"],
            //   "image/jpeg": [".jpg", ".jpeg"],
            //   "image/webp": [".webp"],
            // },
            maxSize: 8192000,
            onDropRejected: (e) => setUploadMessage("Error: " + e.flatMap(e=> e.errors.flatMap(f=>f.message)).join(","))            
          }}
        >
          {uploadMessage && uploadMessage}
        </FileUpload>
        
      }
   </>  
  );
};

const ProfilePictureUpload = ({onSuccess} : {onSuccess(): void}) => {
  return (
    <PageSection>
      <Title headingLevel="h1" size="lg">
        Profile Picture Upload
      </Title>
      <ProfilePictureUploadWidget onSuccess={onSuccess} width={100}></ProfilePictureUploadWidget>

    </PageSection>
  );
}

export { ProfilePictureUpload, ProfilePictureUploadWidget };