import React, { useContext, useEffect } from "react";
import { IProfileMapMarkerProps } from "../../Util/AppData";
import { profilePicSteppedWidthApiUrl } from "../../Util/AppUtil";
import { LocationReportingType } from "../..//Util/LocationReporting";
import { LocationReportingContext } from "../../Util/LocationReportingContext";
import { DragEndEvent, LatLngExpression, LeafletEventHandlerFnMap, Marker as LeafletMarker, LeafletMouseEvent, icon } from "leaflet";
import { Marker } from "react-leaflet";
import { useHistory } from "react-router-dom";
import { ProfileLocationControlType } from "./MapPage";
import { AppStateContext } from "@app/Util/AppState";

interface IMapMarkerProfile {
    profileMapMarkerProps: IProfileMapMarkerProps
    scale_x: number
    mapLocationType: ProfileLocationControlType
}
export const MapMarkerProfile = ({ profileMapMarkerProps, scale_x, mapLocationType }: IMapMarkerProfile) => {
    const locationReporting = React.useContext(LocationReportingContext)
    if (!locationReporting) return <></>
    const reportingLatLng = locationReporting.reportingLatLng
    const history = useHistory()
    const p = profileMapMarkerProps
    const appState = useContext(AppStateContext)
    
    const isMyProfile = p.profileId == appState.settings?.defaultProfileId
    const draggable = isMyProfile && mapLocationType === ProfileLocationControlType.LOCATION_CONTROL_WALK

    let dragEndTimer: null | NodeJS.Timeout = null;
    const markerEventHandler: LeafletEventHandlerFnMap =
    {
        dragend(e: DragEndEvent) {
            const llmarker = e.target as unknown as LeafletMarker;
            locationReporting?.setOverrideLatLng(llmarker.getLatLng());
            locationReporting?.setReportingType(LocationReportingType.REPORT_OVERRIDE_LOCATION);
            //updateMapMarkers();  //Let the auto refresh handle it
        },
        click(e: LeafletMouseEvent) {
            //FIXME Stop this from interfering with Drag
            if (!draggable) history.push('/profile?id=' + profileMapMarkerProps.profileId)
        },
    }

    let className = "markerIcon " + p.profileId;
    if (isMyProfile) className += " me ";    
    //if (profileMapMarker.isHosting) className += "hosting "
    
    if (!p.lat) {
        console.log("Error Marker Properties has LatLng of nulls",p)
        return <></>
    }

    return (
        // .markersTooMany .markerIcon { border-width: 3px;object-fit: cover; }
        <Marker
            key={"prf_" + p.profileId}
            icon={icon({
                className: className,
                iconUrl: profilePicSteppedWidthApiUrl(p.profilePicIds[0], scale_x),
                iconSize: [scale_x, scale_x],
            })}
            position={[p.lat, p.lon]}
            draggable={draggable}
            eventHandlers={markerEventHandler}
            zIndexOffset={isMyProfile ? 0 : p.tsUpdated.getTime() - new Date().getTime()}            
        >
            {/* <Popup>
                <MapMarkerProfilePopupContent profileId={d.profileId} />                                    
            </Popup> */}
        </Marker>
    );
};
